import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo, useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';

import { generateUniqueId } from '../../utils';
import {
  selectMaintenanceMode,
  selectSiteBaseUrl,
  selectSiteConfig,
} from '../../store/selectors/configSelectors';
import { validateHTML } from '../../helpers/validators';
import { SVGImage } from '../../assets/images';

import './Footer.scss';

const Footer: FC = () => {
  const { t } = useTranslation();
  const siteConfig = useSelector(selectSiteConfig);
  const footerCustomHtml = siteConfig?.footer?.templateRef;
  const footerLinks = siteConfig?.footer?.data;
  const [cookiesText, setCookiesText] = useState<any>(null);
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const customLinksMapped = useMemo(
    () =>
      footerLinks?.length
        ? footerLinks?.map(({ id, title, urlSafeName }) => ({
            path: `${siteBaseUrl}/page/${id}${urlSafeName ? `/${urlSafeName}` : ''}`,
            text: title,
          }))
        : [],
    [footerLinks, siteBaseUrl]
  );

  const htmlSnippet = validateHTML(siteConfig?.footerHtml);
  const year = new Date().getFullYear();
  const userBrowserLanguage = navigator.language;
  const airgapWindow = (window as any)?.airgap;

  const getDnssLocalizedText = (browserLang: string): string => {
    const dnssMessages = [
      { codes: ['en-US'], message: 'Do not sell or share my information' },
      { codes: ['fr', 'fr-FR'], message: 'Refuser la vente et le partage de vos informations' },
      { codes: ['ja', 'jp', 'ja-JP'], message: '私の情報を販売または共有しないでください' },
      { codes: ['es', 'es-ES'], message: 'No vender ni compartir mi información' },
      { codes: ['de', 'de-DE'], message: 'Meine Informationen nicht verkaufen oder teilen' },
      { codes: ['it', 'it-IT'], message: 'Non vendere o condividere i miei dati' },
      { codes: ['pt', 'pt-BR'], message: 'Não vender ou compartilhar minhas informações' },
      { codes: ['ko', 'ko-KR'], message: '내 정보 판매 또는 공유 금지' },
      { codes: ['zh', 'zh-CN'], message: '不得出售或共享我的信息' },
      { codes: ['zh-TW', 'zh-HK'], message: '不得出售或分享我的資訊' },
    ];

    const messageObj = dnssMessages.find(msg => msg.codes.includes(browserLang));
    return messageObj ? messageObj.message : 'Do not sell or share my information';
  }


  useEffect(() => {
    if (airgapWindow && airgapWindow.getRegimes().has('US_DNSS')) {
      setCookiesText(getDnssLocalizedText(userBrowserLanguage));
    }
  }, []);

  const handleCookieSettingsClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.transcend?.showConsentManager({
      viewState: 'CompleteOptions'
    });
  };

  return (
    <div className="Footer">
      {/* InnerHTML will execute the html if it's a script */}
      {!!htmlSnippet && <InnerHTML html={htmlSnippet} />}
      {footerCustomHtml && <div dangerouslySetInnerHTML={{ __html: footerCustomHtml }} />}
      {siteConfig && !footerCustomHtml && !maintenanceMode && (
        <>
          <NavLink to="/">
            <img className="Footer-logo" src={SVGImage.NetAppLogo} alt="NetApp Logo" />
          </NavLink>
          {customLinksMapped.map(({ path, text }) => (
            <NavLink key={generateUniqueId()} to={path} className="Footer-link">
              {text}
            </NavLink>
          ))}

          <a target="_self" id="tcm-sdk-show-settings" className="Footer-link" onClick={handleCookieSettingsClick}>
            {cookiesText || t('cookie_settings')}
          </a>

          <div className="Footer-right-text">{t('copyright', { year })}</div>
        </>
      )}
      {maintenanceMode && (
        <>
          <NavLink to="/">
            <img className="Footer-logo" src={SVGImage.NetAppLogo} alt="NetApp Logo" />
          </NavLink>
          <div className="Footer-right-text">{t('copyright', { year })}</div>
        </>
      )}
    </div>
  );
};

export default Footer;
