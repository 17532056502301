/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import { analyticsEmitter } from '../utils/analytics';
import { getStringBetweenStrings } from '../utils';
import { selectSiteLanguage } from '../store/selectors/configSelectors';
import { selectUserExtId, selectValidated } from '../store/selectors/authSelectors';
import { allRoutes } from '../routes/routes';
import config from '../config';
import { AdobeConfig } from '../../types/generic';

declare global {
  interface Window {
    s_gi: any;
    Visitor: any;
  }
}

const isExistingRoute = (route: string): boolean =>
  allRoutes.some((existingRoute) => {
    if (existingRoute.includes(':')) {
      const baseRoute = existingRoute.split('/:')[0];

      return route.startsWith(baseRoute);
    }

    return route === existingRoute;
  });

// Initialize appMeasurement as a variable in the module's scope, so it can be reused across hook instances
let appMeasurement: any = null;

const useAdobeAnalytics = (): void => {
  const [stateParams, setStateParams] = useState<Record<string, string>>({});
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('q') || '';
  const userExtId = useSelector(selectUserExtId) || '';
  const siteLanguage = useSelector(selectSiteLanguage);
  const isValidated = useSelector(selectValidated);
  const location = useLocation();
  const hasCookiesConsent = (window as any)?.airgap?.getConsent?.().confirmed;

  const adobeConfig = useMemo(
    (): AdobeConfig =>
      config.env === 'production'
        ? config.adobe_analytics.config[config.env]
        : config.adobe_analytics.config['qa'],
    []
  );

  useEffect(() => {
    if (!window.s_gi || !hasCookiesConsent) return;

    appMeasurement = window.s_gi(adobeConfig.rsid);

    if (adobeConfig.trackingServer) {
      appMeasurement.trackingServer = adobeConfig.trackingServer;
    }

    // Set up the VisitorAPI component
    if (adobeConfig.marketingCloudOrgId && window?.Visitor?.getInstance) {
      const visitor = window.Visitor.getInstance(adobeConfig.marketingCloudOrgId);

      if (adobeConfig.trackingServer) {
        visitor.trackingServer = adobeConfig.trackingServer;
      }

      if (adobeConfig?.cnameEnabled) {
        visitor.marketingCloudServer = adobeConfig.trackingServer;
      }

      if (adobeConfig.namespace) {
        appMeasurement.visitorNamespace = adobeConfig.namespace;
      }

      appMeasurement.visitor = visitor;
    }
  }, []);

  const isCallbackRoute = (): boolean => {
    const currentRoute = `/${location.pathname.split('/')[1]}`;

    return currentRoute === '/cb';
  };

  const getUserId = (): string => {
    const adobeCookieName = appMeasurement?.visitor.cookieName;
    const adobeCookieValue = adobeCookieName && Cookies.get(adobeCookieName);

    return adobeCookieValue && getStringBetweenStrings('MCMID|', '|', adobeCookieValue);
  };

  useEffect(() => {
    if (isValidated !== null) {
      const currentRoute = `/${location.pathname.split('/')[1]}`;
      const routeExists = isExistingRoute(currentRoute);
      const pageType = routeExists ? (currentRoute === '/' ? 'home' : currentRoute.replace('/', '')) : '';

      const params: Record<string, string> = {
        pageTitle: document.title,
        pageUrl: window.location.href,
        canonicalUrl: document.querySelector("link[rel='canonical']")?.getAttribute('href') || '',
        pageLanguage: siteLanguage || 'en',
        userLanguage: navigator.language,
        userId: getUserId(),
        authenticatedUserId: userExtId,
        pageType,
        errorPage: routeExists ? '' : !isCallbackRoute() ? 'errorPage' : '',
        searchTerm,
        domain: window.location.hostname,
      };

      setStateParams(params);
    }
  }, [location.pathname, userExtId, searchTerm, siteLanguage, isValidated]);

  useEffect(() => {
    const handleCustomPageEvent = (
      eventType: string,
      eventName: string,
      additionalParams?: Record<string, string>
    ): void => {
      sendCustomPageEvent(eventType, eventName, additionalParams);
    };

    analyticsEmitter.on('sendCustomPageEvent', handleCustomPageEvent);

    return () => {
      analyticsEmitter.off('sendCustomPageEvent', handleCustomPageEvent);
    };
  }, [stateParams]);

  const sendCustomPageEvent = (
    eventType: string,
    eventName: string,
    additionalParams?: Record<string, string>
  ): void => {
    const { paramsToTrack } = config.adobe_analytics;

    if (hasCookiesConsent && Object.keys(stateParams).length !== 0) {
      Object.entries(paramsToTrack).forEach(([key, value]) => {
        appMeasurement[value] = stateParams?.[key];
      });

      if (additionalParams) {
        Object.entries(additionalParams).forEach(([key, value]) => {
          appMeasurement[key] = value;
        });
      }

      const paramsArray = Object.values(paramsToTrack);
      const additionalParamsArray = additionalParams ? Object.keys(additionalParams) : [];
      const allParams = paramsArray.concat(additionalParamsArray);
      appMeasurement.linkTrackVars = allParams.join(',');
      appMeasurement.tl(true, eventType, eventName);
    }
  };
};

export default useAdobeAnalytics;
